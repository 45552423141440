import React, {useState, useEffect} from 'react';
import mqtt from 'precompiled-mqtt';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CRow,
  CBadge,
  CSpinner,
} from '@coreui/react';
import {
  FaPlayCircle,
  FaStopCircle,
  FaCheckCircle,
  FaSyncAlt,
} from 'react-icons/fa';
import {RootState, useAppDispatch} from '../../store';
import {useSelector} from 'react-redux';
import {
  onConnect,
  onDisconnect,
  onSubscribe,
  useMqttSelector,
} from '../../slices/mqttSlice';
import {SessionCard} from '../../components/SessionCard';
import {mqttClient} from '../../mqtt_client';

type Message = {
  topic: string;
  message: string;
  timestamp: number;
};

interface MqttReaderProps {
  brokerUrl: string;
}

export const MqttReader: React.FC<MqttReaderProps> = ({brokerUrl}) => {
  const dispatch = useAppDispatch();
  const mqttSelector = useSelector(useMqttSelector);

  useEffect(() => {
    if (!mqttSelector.subscribed) {
      mqttClient.subscribe([
        'up150/+/+/+/started',
        'up150/+/+/+/completed',
        'up150/+/+/+/reset',
        'up150/begin',
        'up150/completed',
      ]);
    }
  }, [mqttSelector.subscribed]);

  // const getLastMessage = (topic: string) => {
  //   const filteredMessages = messages.filter(
  //     message => message.topic === topic
  //   );
  //   if (filteredMessages.length > 0) {
  //     return filteredMessages[filteredMessages.length - 1];
  //   }
  //   return null;
  // };

  // const renderSessionStatus = () => {
  //   const lastStarted = getLastMessage('session/started');
  //   const lastCompleted = getLastMessage('session/completed');
  //   const lastReset = getLastMessage('session/reset');

  //   if (lastStarted) {
  //     return (
  //       <>
  //         <FaPlayCircle className="me-2" />
  //         <strong>Session Started</strong>
  //         <CBadge className="ms-2" color="info">
  //           {lastStarted.timestamp.toLocaleTimeString()}
  //         </CBadge>
  //       </>
  //     );
  //   }

  // if (lastCompleted) {
  //   return (
  //     <>
  //       <FaCheckCircle className="me-2" />
  //       <strong>Session Completed</strong>
  //       <CBadge className="ms-2" color="success">
  //         {lastCompleted.timestamp.toLocaleTimeString()}
  //       </CBadge>
  //     </>
  //   );
  // }

  // if (lastReset) {
  //   return (
  //     <>
  //       <FaSyncAlt className="me-2" />
  //       <strong>Session Reset</strong>
  //       <CBadge className="ms-2" color="warning">
  //         {lastReset.timestamp.toLocaleTimeString()}
  //       </CBadge>
  //     </>
  //   );
  // }

  //   return (
  //     <>
  //       <FaStopCircle className="me-2" />
  //       <strong>Session Not Started</strong>
  //     </>
  //   );
  // };
  // return <>{renderSessionStatus()}</>;
  const renderMessageCard = (message: Message, index: number) => {
    return <SessionCard key={index} {...message} />;
  };

  return (
    <CCard>
      <CCardHeader>
        <strong>Sessioni Live</strong>
        {mqttSelector.connectionState == 'Connected' ? (
          <CBadge className="ms-2" color="success">
            Connesso
          </CBadge>
        ) : (
          <CBadge className="ms-2" color="danger">
            Disconnesso
          </CBadge>
        )}
      </CCardHeader>
      <CCardBody>
        {mqttSelector.messages.length > 0 ? (
          <CRow>{mqttSelector.messages.map(renderMessageCard)}</CRow>
        ) : (
          <p>No messages received yet</p>
        )}
      </CCardBody>
    </CCard>
  );
};
