import {cilPowerStandby, cilSettings} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CAvatar,
  CButton,
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react';
import React from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useReduxAuth, userLogout} from '../slices/authSlice';
import {useAppDispatch} from '../store';

interface IAvatarProp {
  name: string;
  lastName: string;
  email: string;
  onLogout: () => void;
}

export function Avatar({name, lastName, email, onLogout}: IAvatarProp) {
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle className="py-0" caret={false}>
        <CAvatar color="secondary" size="lg">
          {name.charAt(0)}
          {lastName.charAt(0)}
        </CAvatar>
      </CDropdownToggle>
      <CDropdownMenu
        style={{
          position: 'absolute',
          right: 0,
          marginTop: '10px',
        }}
      >
        <CDropdownHeader className="bg-light fw-semibold py-2">
          Account: {email}
        </CDropdownHeader>
        <CDropdownItem href="#">
          <CIcon icon={cilSettings} className="me-2" />
          Settings
        </CDropdownItem>
        <CDropdownItem href="#" onClick={onLogout}>
          <CIcon icon={cilPowerStandby} className="me-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
}

export function LoginButton({onLogin}: any) {
  return (
    <CButton color="primary" onClick={onLogin}>
      Login
    </CButton>
  );
}

function AppHeaderDropDown() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {userInfo} = useSelector(useReduxAuth);
  function onLogOut() {
    dispatch(userLogout());
    navigate('/login');
  }

  return (
    <Avatar
      name={userInfo ? userInfo.name[0] : ''} // should be name but firebase basic auth doesn't fill name and lastname or displayName
      lastName={userInfo ? userInfo.lastName[0] : ''}
      email={userInfo ? userInfo.email : ''}
      onLogout={onLogOut}
    />
  );
}

export default AppHeaderDropDown;
