import React from 'react';
import {Navigate, Route, Routes, createBrowserRouter} from 'react-router-dom';
import Login from './pages/login/Login';
import './scss/style.scss';

import {useSelector} from 'react-redux';
import {AppliancesList} from './pages/appliances/AppliancesList';
import Dashboard from './pages/dashboard/Dashboard';
import DefaultLayout from './pages/layout/DefaultLayout';
import Page401 from './pages/page401/page401';
import Page404 from './pages/page404/Page404';
import Page500 from './pages/page500/Page500';
import SessionsList from './pages/sessions/SessionsList';
import StationsList from './pages/stations/StationsList';
import {useReduxAuth} from './slices/authSlice';
import Page403 from './pages/page403/Page403';

export const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/page500" element={<Page500 />}></Route>
      <Route path="/page401" element={<Page401 />}></Route>
      <Route path="/page403" element={<Page403 />}></Route>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <DefaultLayout />
          </ProtectedRoute>
        }
        errorElement={<Page500 />}
      >
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="stations" element={<StationsList />} />
        <Route path="sessions" element={<SessionsList />} />
        <Route path="appliances" element={<AppliancesList />} />
        <Route path="/" element={<Navigate to="/dashboard" />} />
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

const ProtectedRoute = ({children}: {children: JSX.Element}) => {
  const {userInfo} = useSelector(useReduxAuth);

  if (!userInfo) {
    return <Navigate to="/login" />;
  } else {
    return children;
  }
};
