/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  CButton,
  CCard,
  CCardBody,
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import StationRow from '../../components/StationRow';
import {fetchStations, useStationSelector} from '../../slices/stationSlice';
import axsionInstance from '../../api/axsion-instance';

function StationsList() {
  const data = useSelector(useStationSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    //@ts-ignore
    dispatch(fetchStations());
  }, []);

  return (
    <div>
      <h3>Stazioni</h3>
      <CCard>
        <CCardBody>
          <CTable align="middle" className="mb-0 border" hover responsive>
            <CTableHead color="primary">
              <CTableRow>
                <CTableHeaderCell>Nome</CTableHeaderCell>
                <CTableHeaderCell>Tipo</CTableHeaderCell>
                <CTableHeaderCell>Stato</CTableHeaderCell>
                <CTableHeaderCell>Ultimo Utilizzo</CTableHeaderCell>
                <CTableHeaderCell>Luogo</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {data.stations?.map((item, index) => (
                <StationRow {...item} key={index} />
              ))}
            </CTableBody>
          </CTable>
        </CCardBody>
      </CCard>
    </div>
  );
}

export default StationsList;
