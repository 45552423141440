/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  CButton,
  CCard,
  CCardBody,
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import axios from 'axios';
import querystring from 'querystring-es3';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axsionInstance from '../../api/axsion-instance';
import ApplianceRow from '../../components/ApplianceRow';
import {
  fetchAppliances,
  useAppliancesSelector,
} from '../../slices/applianceSlice';
import {useReduxAuth} from '../../slices/authSlice';

export const AppliancesList = () => {
  const data = useSelector(useAppliancesSelector);
  const dispatch = useDispatch();
  const {userInfo} = useSelector(useReduxAuth);

  useEffect(() => {
    //@ts-ignore
    dispatch(fetchAppliances());
    const payload = window.location.href;
    if (payload) {
      const url = new URL(payload);
      const code = url.searchParams.get('code');
      if (code) {
        const query = querystring.stringify({
          grant_type: 'authorization_code',
          code: code,
          client_id: `${process.env.REACT_APP_HOME_CONNECT_CLIENT_ID}`,
          client_secret: `${process.env.REACT_APP_HOME_CONNECT_CLIENT_SECRET}`,
          redirect_uri: `${process.env.REACT_APP_HOME_CONNECT_REDIRECT}`,
        });
        axios
          .post('https://api.home-connect.com/security/oauth/token', query, {
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
          })
          .then(response => {
            console.log(JSON.stringify(response.data));
            localStorage.setItem(
              'access_token_home_connect',
              response.data.access_token
            );
            localStorage.setItem(
              'id_token_home_connect',
              response.data.id_token
            );
            localStorage.setItem(
              'refresh_token_home_connect',
              response.data.refresh_token
            );
            localStorage.setItem(
              'expires_in_home_connect',
              response.data.expires_in
            );
            localStorage.setItem('scope_home_connect', response.data.scope);
            localStorage.setItem(
              'token_type_home_connect',
              response.data.token_type
            );

            axsionInstance
              .request({
                method: 'POST',
                url: '/appliances/home-connect',
                data: {
                  access_token: response.data.access_token,
                  refresh_token: response.data.refresh_token,
                  expiry_access_token: response.data.expires_in,
                },
              })
              .catch(error => {
                console.log(error);
              });
          })
          .catch(error => {
            console.log(error);
          });
        history.replaceState(null, '', '/appliances');
      }
    }
  }, []);

  function onlogin(
    event: MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ): void {
    const url = `https://api.home-connect.com/security/oauth/authorize?client_id=${process.env.REACT_APP_HOME_CONNECT_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_HOME_CONNECT_REDIRECT}&response_type=code&scope=IdentifyAppliance%20CoffeeMaker`;
    window.location.replace(url);
  }

  function onRefreshToken(
    event: MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ): void {
    // const url = `https://api.home-connect.com/security/oauth/token?grant_type=refresh_token&refresh_token=${localStorage.getItem(
    //   'refresh_token'
    // )}&client_secret=${process.env.REACT_APP_HOME_CONNECT_CLIENT_SECRET}`;
    // console.log(url);

    const query = querystring.stringify({
      grant_type: 'refresh_token',
      refresh_token: localStorage.getItem('refresh_token'),
      client_secret: `${process.env.REACT_APP_HOME_CONNECT_CLIENT_SECRET}`,
    });
    axios
      .post('https://api.home-connect.com/security/oauth/token', query, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(response => {
        JSON.stringify(response.data);
        localStorage.setItem(
          'access_token_home_connect',
          response.data.access_token
        );
        localStorage.setItem('id_token_home_connect', response.data.id_token);
        localStorage.setItem(
          'refresh_token_home_connect',
          response.data.refresh_token
        );
        localStorage.setItem(
          'expires_in_home_connect',
          response.data.expires_in
        );
        localStorage.setItem('scope_home_connect', response.data.scope);
        localStorage.setItem(
          'token_type_home_connect',
          response.data.token_type
        );

        axsionInstance
          .request({
            method: 'POST',
            url: '/appliances/home-connect',
            data: {
              access_token: response.data.access_token,
              refresh_token: response.data.refresh_token,
              expiry_access_token: response.data.expires_in,
            },
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <div>
      <h3>Appliances</h3>
      <CCard>
        {userInfo && userInfo.role === 'backend' && (
          <div>
            <CButton onClick={onlogin}>Home Connect </CButton>
            <CButton onClick={onRefreshToken}>Refresh Token Connect </CButton>
          </div>
        )}
        <CCardBody>
          <CTable align="middle" className="mb-0 border" hover responsive>
            <CTableHead color="primary">
              <CTableRow>
                <CTableHeaderCell>Nome</CTableHeaderCell>
                <CTableHeaderCell>Brand</CTableHeaderCell>
                <CTableHeaderCell>Connessione</CTableHeaderCell>
                <CTableHeaderCell>Data Creazione</CTableHeaderCell>
                <CTableHeaderCell>Status</CTableHeaderCell>
                <CTableHeaderCell>Luogo</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {data.appliances?.map((item, index) => (
                <ApplianceRow {...item} key={index} />
              ))}
            </CTableBody>
          </CTable>
        </CCardBody>
      </CCard>
    </div>
  );
};
