import {CFooter} from '@coreui/react';
import React from 'react';

function AppFooter() {
  return (
    <CFooter>
      <div>
        <a href="https://hwasrl.it" target="_blank" rel="noopener noreferrer">
          HWA Srl
        </a>
        <span className="ms-1">&copy; 2023</span>
      </div>
      <div className="ms-auto">
        <span className="me-1">UI Powered by</span>
        <a
          href="https://coreui.io/react"
          target="_blank"
          rel="noopener noreferrer"
        >
          CoreUI React Admin &amp; Dashboard Template
        </a>
      </div>
    </CFooter>
  );
}

export default AppFooter;
