import {createSlice, PayloadAction} from '@reduxjs/toolkit';
//prettier-ignore
import { AppThunk, RootState } from '../store';
//prettier-ignore
import axsionInstance from '../api/axsion-instance';

export interface IStationState {
  isloading: boolean;
  stations: UP150Station[];
  error: string;
}
export const initialState: IStationState = {
  isloading: false,
  stations: [],
  error: '',
};

export const stationSlice = createSlice({
  name: 'stations',
  initialState,
  reducers: {
    startGetSession: (state, {payload}: PayloadAction<boolean>) => {
      state.isloading = payload;
      state.stations = [];
    },
    successGetStations: (
      state,
      {payload}: PayloadAction<{stations: UP150Station[]}>
    ) => {
      state.isloading = false;
      state.stations = payload.stations;
    },
    errorGetStations: (state, {payload}: PayloadAction<{error: string}>) => {
      state.isloading = false;
      state.stations = [];
      state.error = payload.error;
    },
  },
});

export const fetchStations = (): AppThunk => async (dispatch: any) => {
  dispatch(startGetSession(true));
  try {
    const response = await axsionInstance.request({
      url: `/stations`,
    });
    const data = response.data;

    dispatch(successGetStations({stations: data}));
  } catch (error) {
    dispatch(errorGetStations({error: 'Error fetching stations'})); //TODO fix me when error handling is implemented
  }
};
export const {startGetSession, successGetStations, errorGetStations} =
  stationSlice.actions;

export const useStationSelector = (state: RootState) => state.stationSlice;
export default stationSlice.reducer;
