import {cilChartLine, cilDevices, cilUser} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CAlert,
  CCard,
  CCardBody,
  CCardText,
  CCardTitle,
  CCol,
  CContainer,
  CRow,
  CTable,
  CTableBody,
  CWidgetStatsF,
} from '@coreui/react';
import React, {useEffect} from 'react';
import QRCode from 'react-qr-code';
import {useSelector} from 'react-redux';
import {useReduxAuth} from '../../slices/authSlice';
import {fetchSessions, useSessionSelector} from '../../slices/sessionSlice';
import {fetchStations, useStationSelector} from '../../slices/stationSlice';
import {useAppDispatch} from '../../store';
import {MqttReader} from '../mqttreader/MqttReader';

function Dashboard() {
  const dispatch = useAppDispatch();
  const stationSelector = useSelector(useStationSelector);
  const sessionSelector = useSelector(useSessionSelector);
  const {userInfo} = useSelector(useReduxAuth);

  useEffect(() => {
    dispatch(fetchStations());

    dispatch(fetchSessions());
  }, []);

  return (
    <CContainer lg>
      <h3>Dashboard</h3>
      <CTable>
        <CTableBody>
          <CRow>
            <CCol>
              <CWidgetStatsF
                className="mb-3"
                color="primary"
                icon={<CIcon icon={cilUser} height={24} />}
                title="Utenti"
                value="1"
              />
            </CCol>
            <CCol>
              <CWidgetStatsF
                className="mb-3"
                color="secondary"
                icon={<CIcon icon={cilDevices} height={24} />}
                title="Stazioni"
                value={stationSelector.stations?.length}
              />
            </CCol>
            <CCol>
              <CWidgetStatsF
                className="mb-3"
                color="warning"
                icon={<CIcon icon={cilChartLine} height={24} />}
                title="Sessioni"
                value={sessionSelector.sessions?.length}
              />
            </CCol>
          </CRow>
          <CRow>
            {stationSelector.stations?.map((item, index) => {
              return (
                <CCol key={index}>
                  <CCard className="text-center">
                    <CCardBody>
                      <CCardTitle>{item.name}</CCardTitle>
                      <CCardText>Scan the QR Code to begin</CCardText>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          gap: '20px ',
                        }}
                      >
                        <QRCode
                          //change user id from dev database to prod database
                          //prod user id : 0c1547ba-6186-42a1-bbbf-bd59182754a9
                          //dev user id : c1d6d863-4a93-4b4d-ad29-aa81521c6ab0
                          value={`${process.env.REACT_APP_BASE_URL}/sessions/create/${userInfo?.id}/${item.id}`}
                          size={100}
                        />
                        {item.status === 'available' ? (
                          <CAlert color="success">Available</CAlert>
                        ) : (
                          <CAlert color="danger">Busy</CAlert>
                        )}
                      </div>
                    </CCardBody>
                  </CCard>
                </CCol>
              );
            })}
          </CRow>
          <CRow>
            <CCol>
              <MqttReader brokerUrl="mqtt://test.mosquitto.org" />
            </CCol>
          </CRow>
        </CTableBody>
      </CTable>
    </CContainer>
  );
}

export {Dashboard as default};
