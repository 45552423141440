import {createSlice, PayloadAction} from '@reduxjs/toolkit';
//prettier-ignore
import { AppThunk, RootState } from '../store';
//prettier-ignore
import axsionInstance from '../api/axsion-instance';

export interface IAppliancesState {
  isloading: boolean;
  appliances: Appliance[];
  error: string;
}
export const initialState: IAppliancesState = {
  isloading: false,
  appliances: [],
  error: '',
};

export const appliancesSlice = createSlice({
  name: 'appliances',
  initialState,
  reducers: {
    startGetAppliances: (state, {payload}: PayloadAction<boolean>) => {
      state.isloading = payload;
      state.appliances = [];
    },
    successGetAppliances: (
      state,
      {payload}: PayloadAction<{appliances: Appliance[]}>
    ) => {
      state.isloading = false;
      state.appliances = payload.appliances;
    },
    errorGetAppliances: (state, {payload}: PayloadAction<{error: string}>) => {
      state.isloading = false;
      state.appliances = [];
      state.error = payload.error;
    },
  },
});

export const fetchAppliances = (): AppThunk => async (dispatch: any) => {
  dispatch(startGetAppliances(true));
  try {
    const response = await axsionInstance.request({
      url: `/appliances`,
    }); //TODO:fixme
    const data = await response.data;
    dispatch(successGetAppliances({appliances: data}));
  } catch (error) {
    dispatch(errorGetAppliances({error: 'Error fetching appliances'})); //TODO fix me when error handling is implemented
  }
};
export const {successGetAppliances, startGetAppliances, errorGetAppliances} =
  appliancesSlice.actions;

export const useAppliancesSelector = (state: RootState) =>
  state.appliancesSlice;

export default appliancesSlice.reducer;
