import {CContainer, CSpinner} from '@coreui/react';
import React, {Suspense} from 'react';
import {Outlet} from 'react-router';

import Dashboard from '../pages/dashboard/Dashboard';
import SessionsList from '../pages/sessions/SessionsList';
import StationsList from '../pages/stations/StationsList';
const routes = [
  {path: 'dashboard', element: <Dashboard />},
  {path: 'sessions', element: <SessionsList />},
  {path: 'stations', element: <StationsList />},
];
function AppContent() {
  return (
    <CContainer lg>
      <Suspense fallback={<CSpinner color="primary" />}>
        {/* <Routes>
          {routes.map((route, i) => (
            <Route key={i} path={route.path} element={route.element} />
          ))}
        </Routes> */}
        <Outlet />
      </Suspense>
    </CContainer>
  );
}

export default React.memo(AppContent);
