import {
  CContainer,
  CHeader,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CTableDataCell,
  CTableRow,
} from '@coreui/react';
import React, {useState} from 'react';

export interface IStationProp {
  station: UP150Station;
}

function StationRow({
  id,
  created_at,
  updated_at,
  status,
  name,
  location,
  gym_tool: gym_tool,
}: UP150Station) {
  const [showDetails, setShowDetails] = useState(false);
  const date = new Date(updated_at).toLocaleString('it-IT', {
    timeZone: 'Europe/Rome',
  });

  return (
    <CTableRow
      key={id}
      onClick={() => {
        setShowDetails(!showDetails);
        //  console.log(`Device ${devices}`);
      }}
    >
      <CTableDataCell>{name}</CTableDataCell>

      {gym_tool ? (
        <CTableDataCell>{gym_tool?.gym_tool_type}</CTableDataCell>
      ) : (
        <CTableDataCell>Non assegnato</CTableDataCell>
      )}
      <CTableDataCell>
        {status === 'busy' ? 'Occupata' : 'Libera'}
      </CTableDataCell>
      <CTableDataCell>{date}</CTableDataCell>
      <CTableDataCell>{location?.name}</CTableDataCell>
      <CModal
        size="lg"
        visible={showDetails}
        onClose={() => setShowDetails(false)}
      >
        <CModalHeader>
          <CModalTitle>User Details</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer>
            <CHeader>Attrezzo</CHeader>
          </CContainer>
          {/* <CCardGroup>
            {devices?.map((device, key) => {
              return (
                <CCard key={key} style={{width: '18rem'}}>
                  <CCardHeader>{device.macaddress}</CCardHeader>
                  <CListGroup flush>
                    {Object.entries(device).map(([key, value]) => {
                      if (device.u == id) {
                        return (
                          <CListGroupItem>
                            {key} : {typeof value === 'object' ? '' : value}
                          </CListGroupItem>
                        );
                      }
                    })}
                  </CListGroup>
                </CCard>
              );
            })}
          </CCardGroup> */}
        </CModalBody>
      </CModal>
    </CTableRow>
  );
}

export default StationRow;

function UserModalDetails({name, email, show}: any) {
  const [showDetails, setShowDetails] = useState(show);
  return (
    <CModal size="lg" onClose={() => setShowDetails(false)}>
      <CModalHeader>
        <CModalTitle>User Details</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <h1>{!name ? 'Anonymous' : name}</h1>
        <h6>{email}</h6>
      </CModalBody>
    </CModal>
  );
}
