import {createSlice, PayloadAction} from '@reduxjs/toolkit';
//prettier-ignore
import { AppThunk, RootState } from '../store';
//prettier-ignore
import axsionInstance from '../api/axsion-instance';

export interface ISessionState {
  isLoading: boolean;
  sessions: UP150Session[];
  error: string;
}
export const initialState: ISessionState = {
  isLoading: false,
  sessions: [],
  error: '',
};

export const sessionSlice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    startGetSessions: (state, {payload}: PayloadAction<boolean>) => {
      state.sessions = [];
      state.isLoading = payload;
      state.error = '';
    },
    successGetSessions: (
      state,
      {payload}: PayloadAction<{sessions: UP150Session[]}>
    ) => {
      return {
        sessions: payload.sessions,
        isLoading: false,
        error: '',
      };
    },
    errorGetSessions: (state, {payload}: PayloadAction<{error: string}>) => {
      return {
        sessions: [],
        isLoading: false,
        error: payload.error,
      };
    },
  },
});

export const fetchSessions = (): AppThunk => async dispatch => {
  dispatch(startGetSessions(true));
  try {
    const response = await axsionInstance.request({
      url: `/sessions`,
    });
    const data = response.data;
    dispatch(successGetSessions({sessions: data}));
  } catch (error) {
    dispatch(errorGetSessions({error: 'Error fetching sessions'})); //TODO fix me when error handling is implemented
  }
};
export const {startGetSessions, errorGetSessions, successGetSessions} =
  sessionSlice.actions;

export const useSessionSelector = (state: RootState) => state.sessionSlice;
export default sessionSlice.reducer;
