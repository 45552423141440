import {cilXCircle} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CTableDataCell,
  CTableRow,
} from '@coreui/react';
import React, {useState} from 'react';
import axiosInstance from '../api/axsion-instance';

export interface ISessionProp {
  session: UP150Session;
}

function SessionRow({
  id,
  created_at,
  status,
  station,
  updated_at,
}: UP150Session) {
  const [showDetails, setShowDetails] = useState(false);
  const date = new Date(created_at).toLocaleString('it-IT', {
    timeZone: 'Europe/Rome',
  });
  const update = new Date(updated_at).toLocaleString('it-IT', {
    timeZone: 'Europe/Rome',
  });

  function handleButton(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    e.preventDefault();
    axiosInstance
      .patch(`/sessions/${id}`, {
        id: id,
        status: 'aborted',
        is_opened: false,
      })
      .then(res => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  }
  return (
    <CTableRow
      key={id}
      onClick={() => {
        setShowDetails(!showDetails);
        //  console.log(`Device ${devices}`);
      }}
    >
      <CTableDataCell>{status.toUpperCase()}</CTableDataCell>
      <CTableDataCell>{date}</CTableDataCell>
      <CTableDataCell>{update}</CTableDataCell>
      <CTableDataCell>{station.name}</CTableDataCell>
      <CTableDataCell>
        {station.status === 'busy' ? 'Occupata' : 'Libera'}
      </CTableDataCell>
      <CTableDataCell>
        <CButton
          color="transparent"
          disabled={station.status === 'busy' ? false : true}
          onClick={handleButton}
        >
          <CIcon color="primary" icon={cilXCircle} size="xxl"></CIcon>
        </CButton>
      </CTableDataCell>
      {/* <CModal
        size="lg"
        visible={showDetails}
        onClose={() => setShowDetails(false)}
      >
        <CModalHeader>
          <CModalTitle>User Details</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer>
            <CHeader>Attrezzo</CHeader>
          </CContainer>
          {<CCardGroup>
              {devices?.map((device, key) => {
                return (
                  <CCard key={key} style={{width: '18rem'}}>
                    <CCardHeader>{device.macaddress}</CCardHeader>
                    <CListGroup flush>
                      {Object.entries(device).map(([key, value]) => {
                        if (device.u == id) {
                          return (
                            <CListGroupItem>
                              {key} : {typeof value === 'object' ? '' : value}
                            </CListGroupItem>
                          );
                        }
                      })}
                    </CListGroup>
                  </CCard>
                );
              })}
            </CCardGroup> }
        </CModalBody>
      </CModal> */}
    </CTableRow>
  );
}

export default SessionRow;

function UserModalDetails({name, email, show}: any) {
  const [showDetails, setShowDetails] = useState(show);
  return (
    <CModal size="lg" onClose={() => setShowDetails(false)}>
      <CModalHeader>
        <CModalTitle>User Details</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <h1>{!name ? 'Anonymous' : name}</h1>
        <h6>{email}</h6>
      </CModalBody>
    </CModal>
  );
}
