import {Mutex} from 'async-mutex';
import axios from 'axios';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import {refreshAccessToken} from '../slices/authSlice';

let store: {dispatch: ThunkDispatch<any, any, AnyAction>};

export const injectStore = (_store: any) => {
  store = _store;
};

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  timeout: 1000 * 5,
});

const tokenRefreshMutex = new Mutex();
let isRefreshing = false;

const refreshToken = async (refresh_token: string) => {
  const release = await tokenRefreshMutex.acquire();

  try {
    if (!isRefreshing) {
      isRefreshing = true;
      // Perform token refresh logic

      const response = await axios.request({
        baseURL: `${process.env.REACT_APP_BASE_URL}`,
        url: '/auth/refresh',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${refresh_token}`,
        },
      });
      const newToken = response.data.token;
      const newExpiry = response.data.expiry;
      const newRefreshToken = response.data.refresh_token;
      localStorage.removeItem('bearer-token');
      localStorage.removeItem('expiry');
      localStorage.removeItem('refresh-bearer-token');
      localStorage.removeItem('user');
      // Update local storage with new token and expiry
      localStorage.setItem('bearer-token', newToken);
      localStorage.setItem('expiry', newExpiry);
      localStorage.setItem('refresh-bearer-token', newRefreshToken);
      localStorage.setItem('user', JSON.stringify(response.data));
      await store.dispatch(refreshAccessToken());
      isRefreshing = false;
      return newToken;
    } else {
      await tokenRefreshMutex.waitForUnlock();
      return Promise.reject('Token refresh already in progress');
    }
  } catch (error) {
    // Handle error
  } finally {
    release();
  }
};

const isTokenExpired = () => {
  const expiry = localStorage.getItem('expiry');
  if (!expiry) {
    return true; // Token is considered expired if expiry is not available
  }

  const expiryDate = new Date(parseInt(expiry) - 60000); // removing 1 minute from expiry
  const currentDate = new Date();
  return currentDate.getTime() >= expiryDate.getTime();
};

// Axios request interceptor
axiosInstance.interceptors.request.use(async config => {
  await tokenRefreshMutex.waitForUnlock();

  if (isTokenExpired()) {
    if (!tokenRefreshMutex.isLocked()) {
      // Queue the request and wait for token refresh
      await refreshToken(localStorage.getItem('refresh-bearer-token') || '');
    } else {
      await tokenRefreshMutex.waitForUnlock();
    }
  }
  // Set token in request header
  const bearerToken = localStorage.getItem('bearer-token');
  config.headers['Authorization'] = `Bearer ${bearerToken}`;
  return config;
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    // console.log(error.response.data);
    if (error.response.status === 401) {
      if (window.location.pathname !== '/login')
        window.location.href = '/login';
    }
    if (
      error.response.status === 403 &&
      error.response.data.message === 'Token expired'
    ) {
      //do nothing token refresh will handle this
    } else {
      window.location.href = '/page403';
    }
    if (error.response.status === 404) {
      window.location.href = '/page404';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
