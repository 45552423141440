import {Action, AnyAction, configureStore} from '@reduxjs/toolkit';

import thunk, {ThunkAction, ThunkDispatch} from 'redux-thunk';

import {useDispatch} from 'react-redux';
import logger from 'redux-logger';
import {rootReducer} from './reducer/rootReducer';

const middleware: any = [thunk]; //thunk middleware need any to be able to add logger for development

//if production mode, remove logger
if (process.env.REACT_APP_ENV === 'development') {
  middleware.push(logger);
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: [...middleware],
});

// // Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>;

export const useAppDispatch = (arg?: unknown) =>
  useDispatch<TypedDispatch<RootState>>();
// AppThunk type for defining your thunk actions
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
