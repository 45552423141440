import {createSlice, PayloadAction} from '@reduxjs/toolkit';
//prettier-ignore
import { RootState } from '../store';
//prettier-ignore
import { mqttClient } from '../mqtt_client';

const initialState: IMQTTState = {
  connectionState: mqttClient.connected ? 'Connected' : 'Disconnected',
  subscribed: false,
  lastMessage: null,
  messages: [],
  error: null,
};

export const mqttSlice = createSlice({
  name: 'mqtt_connection',
  initialState,
  reducers: {
    onConnect: state => {
      return {
        ...state,
        connectionState: 'Connected',
      };
    },
    onSubscribe: (state, {payload}: PayloadAction<{subscribed: boolean}>) => {
      return {
        ...state,
        subscribed: payload.subscribed,
      };
    },
    onMessage: (state, {payload}: PayloadAction<{message: Message}>) => {
      return {
        ...state,
        lastMessage: payload.message,
        messages: [...state.messages, payload.message],
      };
    },
    onDisconnect: state => {
      return {
        ...state,
        connectionState: 'Disconnected',
      };
    },
    onError: (state, {payload}: PayloadAction<{error: string}>) => {
      return {
        ...state,
        connectionState: 'Error',
        error: payload.error,
      };
    },
  },
});

export const {onConnect, onDisconnect, onSubscribe, onMessage, onError} =
  mqttSlice.actions;

export const useMqttSelector = (state: RootState) => state.mqttSlice;
export default mqttSlice.reducer;
