/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  CCard,
  CCardBody,
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SessionRow from '../../components/SessionRow';
import {fetchSessions, useSessionSelector} from '../../slices/sessionSlice';
function SessionsList() {
  const data = useSelector(useSessionSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    //@ts-ignore
    dispatch(fetchSessions());
  }, []);

  return (
    <div>
      <h3>Sessioni</h3>
      <CCard>
        <CCardBody>
          <CTable align="middle" className="mb-0 border" hover responsive>
            <CTableHead color="primary">
              <CTableRow>
                <CTableHeaderCell>Stato</CTableHeaderCell>
                <CTableHeaderCell>Creazione</CTableHeaderCell>
                <CTableHeaderCell>Aggiornata</CTableHeaderCell>
                <CTableHeaderCell>Stazione</CTableHeaderCell>
                <CTableHeaderCell>Stato Stazione</CTableHeaderCell>
                <CTableHeaderCell>Chiudi Sessione</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {data.sessions?.map((item, index) => (
                <SessionRow {...item} key={index} />
              ))}
            </CTableBody>
          </CTable>
        </CCardBody>
      </CCard>
    </div>
  );
}

export default SessionsList;
