//prettier-ignore
import { combineReducers } from 'redux';
//prettier-ignore
import authSlice from '../slices/authSlice';
//prettier-ignore
import appliancesSlice from '../slices/applianceSlice';
//prettier-ignore
import togglerSlice from '../slices/togglerSlicer';
//prettier-ignore
import connectionSlice from '../slices/connectionSlice';
import mqttSlice from '../slices/mqttSlice';
import sessionSlice from '../slices/sessionSlice';
import stationSlice from '../slices/stationSlice';

export const rootReducer = combineReducers({
  authSlice,
  appliancesSlice,
  togglerSlice,
  connectionSlice,
  sessionSlice,
  stationSlice,
  mqttSlice,
});
