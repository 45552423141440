import {cilDevices, cilUser} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CImage,
  CNavItem,
  CNavLink,
  CNavTitle,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
} from '@coreui/react';
import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import SimpleBar from 'simplebar-react';

import {useSelector} from 'react-redux';
import '../scss/style.scss';
import {setSidebarShow, useSidebarSelector} from '../slices/togglerSlicer';
import {useAppDispatch} from '../store';

function AppSidebar() {
  const data = useNavigate();
  const dispatch = useAppDispatch();
  const sidebar = useSelector(useSidebarSelector);
  const location = useLocation();
  return (
    <CSidebar
      position="fixed"
      visible={sidebar.sidebarShow}
      onVisibleChange={visible => {
        dispatch(setSidebarShow(visible));
      }}
    >
      <CSidebarBrand>
        <CImage src="" height={50}></CImage>
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <CNavTitle>Menu</CNavTitle>
          <CNavItem>
            <CNavLink
              active={location.pathname.includes('dashboard')}
              onClick={() => {
                data('/dashboard');
              }}
            >
              <CIcon icon={cilDevices} customClassName="nav-icon" />
              Dashboard
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink
              active={location.pathname.includes('stations')}
              onClick={() => {
                data('/stations');
              }}
            >
              <CIcon icon={cilUser} customClassName="nav-icon" />
              Stazioni
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink
              active={location.pathname.includes('sessions')}
              onClick={() => {
                data('/sessions');
              }}
            >
              <CIcon icon={cilDevices} customClassName="nav-icon" />
              Sessioni
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink
              active={location.pathname.includes('appliances')}
              onClick={() => {
                data('/appliances');
              }}
            >
              <CIcon icon={cilDevices} customClassName="nav-icon" />
              Elettrodomestici
            </CNavLink>
          </CNavItem>
        </SimpleBar>
      </CSidebarNav>
    </CSidebar>
  );
}

export default React.memo(AppSidebar);
