import {IClientOptions} from 'mqtt';
import mqtt from 'precompiled-mqtt';
import {
  onConnect,
  onDisconnect,
  onError,
  onMessage,
  onSubscribe,
} from './slices/mqttSlice';
import {fetchStations} from './slices/stationSlice';
import store from './store';

const options: IClientOptions = {
  keepalive: 30,
  protocolId: 'MQTT',
  protocolVersion: 4,
  clean: true,
  reconnectPeriod: 1000,
  connectTimeout: 30 * 1000,
  will: {
    topic: 'WillMsg',
    payload: 'Connection Closed abnormally..!',
    qos: 0,
    retain: false,
  },
  rejectUnauthorized: false,
};
options.clientId = 'mqttjs_' + Math.random().toString(16).substr(2, 8);
options.username = process.env.REACT_APP_MQTT_USERNAME;
options.password = process.env.REACT_APP_MQTT_PASSWORD;
export const mqttClient = mqtt.connect(
  `${process.env.REACT_APP_MQTT_URL}`,
  options
);

mqttClient.on('message', (receivedTopic, receivedMessage) => {
  console.log(`Received message on topic ${receivedTopic}: ${receivedMessage}`);
  const newMessage: Message = {
    topic: receivedTopic,
    message: receivedMessage.toString(),
    timestamp: new Date().getMilliseconds(),
  };
  if (
    newMessage.topic === 'up150/begin' ||
    newMessage.topic === 'up150/completed'
  ) {
    console.log('Fetching stations');
    fetchStations();
    window.location.reload();
  } else {
    store.dispatch(onMessage({message: newMessage}));
  }
});

mqttClient.on('error', err => {
  console.log(`Error connecting to MQTT broker: ${err}`);
  store.dispatch(
    onError({
      error: err.message,
    })
  );
});

mqttClient.on('close', () => {
  console.log('Disconnected from MQTT broker');
  store.dispatch(onDisconnect());
});

mqttClient.on('connect', () => {
  console.log('Connected to MQTT broker');
  store.dispatch(onConnect());
  mqttClient.subscribe(
    ['up150/#', 'up150/begin', 'up150/completed'],
    (err, granted) => {
      if (err) {
        console.log(`Error subscribing to MQTT topics: ${err}`);
        store.dispatch(
          onSubscribe({
            subscribed: false,
          })
        );
        store.dispatch(
          onError({
            error: err.message,
          })
        );
      } else {
        console.log(`Subscribed to MQTT topics: ${granted}`);
        store.dispatch(
          onSubscribe({
            subscribed: true,
          })
        );
      }
    }
  );
});
