import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';

const initialState: ConnectionState = {
  status: 'Offline',
};

const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    apiOK: state => {
      state.status = 'Online';
    },
    apiDown: state => {
      state.status = 'Offline';
    },
  },
});

export const {apiOK} = connectionSlice.actions;
export const useConnectionSelector = (state: RootState) =>
  state.connectionSlice;
export default connectionSlice.reducer;
