import {
  CContainer,
  CHeader,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CTableDataCell,
  CTableRow,
} from '@coreui/react';
import React, {useState} from 'react';

function ApplianceRow({
  id,
  created_at,
  name,
  type,
  connection,
  brand,
  haid,
}: Appliance) {
  const [showDetails, setShowDetails] = useState(false);
  const date = new Date(created_at).toLocaleString('it-IT', {
    timeZone: 'Europe/Rome',
  });

  return (
    <CTableRow
      key={id}
      onClick={() => {
        setShowDetails(!showDetails);
        //  console.log(`Device ${devices}`);
      }}
    >
      <CTableDataCell>{name}</CTableDataCell>

      <CTableDataCell>{brand}</CTableDataCell>

      <CTableDataCell>{type}</CTableDataCell>
      <CTableDataCell>{connection}</CTableDataCell>
      <CTableDataCell>status</CTableDataCell>
      <CTableDataCell>{date}</CTableDataCell>
      <CModal
        size="lg"
        visible={showDetails}
        onClose={() => setShowDetails(false)}
      >
        <CModalHeader>
          <CModalTitle>User Details</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer>
            <CHeader>Appliance</CHeader>
          </CContainer>
          {/* <CCardGroup>
              {devices?.map((device, key) => {
                return (
                  <CCard key={key} style={{width: '18rem'}}>
                    <CCardHeader>{device.macaddress}</CCardHeader>
                    <CListGroup flush>
                      {Object.entries(device).map(([key, value]) => {
                        if (device.u == id) {
                          return (
                            <CListGroupItem>
                              {key} : {typeof value === 'object' ? '' : value}
                            </CListGroupItem>
                          );
                        }
                      })}
                    </CListGroup>
                  </CCard>
                );
              })}
            </CCardGroup> */}
        </CModalBody>
      </CModal>
    </CTableRow>
  );
}

export default ApplianceRow;

function UserModalDetails({name, email, show}: any) {
  const [showDetails, setShowDetails] = useState(show);
  return (
    <CModal size="lg" onClose={() => setShowDetails(false)}>
      <CModalHeader>
        <CModalTitle>User Details</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <h1>{!name ? 'Anonymous' : name}</h1>
        <h6>{email}</h6>
      </CModalBody>
    </CModal>
  );
}
