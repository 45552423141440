import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';

const initialState: SidebarState = {
  sidebarShow: true,
};

export const togglerSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebarShow: (state, {payload}: PayloadAction<boolean>) => {
      state.sidebarShow = payload;
    },
  },
});
export const {setSidebarShow} = togglerSlice.actions;
export default togglerSlice.reducer;
export const useSidebarSelector = (state: RootState) => state.togglerSlice;
