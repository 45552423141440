import {cilLockLocked, cilUser} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
} from '@coreui/react';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {UserLoginData, useReduxAuth, userLogin} from '../../slices/authSlice';
import {useAppDispatch} from '../../store';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const {loading, userInfo, error} = useSelector(useReduxAuth);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  // redirect authenticated user to profile screen
  useEffect(() => {
    if (userInfo) {
      navigate('/dashboard');
    }
  }, [navigate, userInfo, error]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const loginData: UserLoginData = {email, password}; // Adjust this according to your types

    dispatch(userLogin(loginData));
  };
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCard className="p-4">
              <CCardBody>
                <CForm onSubmit={handleSubmit}>
                  <h1>Login</h1>
                  <p className="text-medium-emphasis">
                    Sign In to your account
                  </p>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilUser} />
                    </CInputGroupText>
                    <CFormInput
                      placeholder="Email"
                      autoComplete="email"
                      onChange={event => {
                        setEmail(event.target.value);
                      }}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-4">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      type="password"
                      placeholder="Password"
                      autoComplete="current-password"
                      onChange={event => {
                        setPassword(event.target.value);
                      }}
                    />
                  </CInputGroup>
                  <CRow>
                    <CCol xs={6}>
                      {!loading ? (
                        <CButton color="primary" className="px-4" type="submit">
                          Login
                        </CButton>
                      ) : (
                        <CButton color="primary" disabled>
                          <CSpinner
                            component="span"
                            size="sm"
                            aria-hidden="true"
                          />
                        </CButton>
                      )}
                    </CCol>
                    <CCol xs={6} className="text-right">
                      <CButton color="link" className="px-0">
                        Forgot password?
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        {error && (
          <CAlert color="danger">
            The username or password provided were incorrect!
          </CAlert>
        )}
      </CContainer>
    </div>
  );
};

export {Login as default};
