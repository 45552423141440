import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

//prettier-ignore
import axios from 'axios';
//prettier-ignore
import jwt_decode, { JwtPayload } from "jwt-decode";
import axiosInstance from '../api/axsion-instance';
import {RootState} from '../store';

export interface UserLoginData {
  email: string;
  password: string;
}
export interface IAuthState {
  loading: boolean;
  isRefreshing: boolean;
  userInfo: User | null;
  success: boolean;
  error: string | null;
}
const getInitialstate = (): IAuthState => {
  if (localStorage.getItem('user') === null) {
    return {
      loading: false,
      isRefreshing: false,
      userInfo: null, // for user object
      error: null,
      success: false, // for monitoring the registration process.
    };
  }
  const userInfo = JSON.parse(localStorage.getItem('user') as string);
  if (userInfo.expiry < Date.now()) {
    if (userInfo.refresh_token) {
      //check refresh token expiry

      const refresh_decoded = jwt_decode<JwtPayload>(userInfo.refresh_token);
      if (
        refresh_decoded &&
        refresh_decoded.exp &&
        refresh_decoded.exp * 1000 < Date.now()
      ) {
        console.log('refresh token expired');
        return {
          loading: false,
          isRefreshing: false,
          userInfo: null, // for user object
          error: null,
          success: false, // for monitoring the registration process.
        };
      }
    }
  }
  return {
    loading: false,
    isRefreshing: false,
    userInfo: userInfo, // for user object
    error: null,
    success: false, // for monitoring the registration process.
  };
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: getInitialstate(),
  reducers: {
    // logout user
  },
  extraReducers: builder => {
    // login user
    builder.addCase(userLogin.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(userLogin.fulfilled, (state, {payload}) => {
      state.loading = false;
      state.userInfo = payload;
      state.success = true;
    });
    builder.addCase(userLogin.rejected, (state, {payload}) => {
      state.loading = false;
      state.error = payload != undefined ? payload : null;
    });

    builder.addCase(userLogout.pending, state => {
      state.loading = false;
      state.userInfo = null;
      state.error = null;
    });
    builder.addCase(userLogout.fulfilled, state => {
      state.loading = false;
      state.userInfo = null;
      state.success = true;
    });
    builder.addCase(userLogout.rejected, (state, {payload}) => {
      state.loading = false;
      state.userInfo = null;
      state.error = payload != undefined ? payload : null;
    });

    builder.addCase(refreshAccessToken.pending, state => {
      state.isRefreshing = true;
    });
    builder.addCase(refreshAccessToken.fulfilled, (state, {payload}) => {
      state.isRefreshing = false;
      state.userInfo = payload;
    });

    builder.addCase(refreshAccessToken.rejected, (state, {payload}) => {
      state.isRefreshing = false;
      state.error = payload != undefined ? payload : null;
    });
  },
});

export const userLogin = createAsyncThunk<
  User,
  UserLoginData,
  {rejectValue: string}
>('auth/login', async ({email, password}, {rejectWithValue}) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/auth/login`,
      {
        email,
        password,
      }
    );
    // store user's token in local storage
    if (
      response.status === 200 ||
      (response.status === 201 && response.data.token)
    ) {
      const user: User = {
        name: response.data.name,
        lastName: response.data.lastName,
        email: response.data.email,
        role: response.data.role,
        token: response.data.token,
        refresh_token: response.data.refresh_token,
        expiry: response.data.expiry,
        id: response.data.id,
      };
      localStorage.setItem('bearer-token', response.data.token);
      localStorage.setItem('refresh-bearer-token', response.data.refresh_token);
      localStorage.setItem('expiry', response.data.expiry);
      localStorage.setItem('user', JSON.stringify(user));
      return user;
    } else {
      return rejectWithValue('Error logging in');
    }
  } catch (error: any) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const userLogout = createAsyncThunk<void, void, {rejectValue: string}>(
  'auth/logout',
  async (_, {rejectWithValue}) => {
    try {
      const logout = await axiosInstance.request({
        url: '/auth/logout',
        method: 'POST',
        data: JSON.stringify(localStorage.getItem('user')),
      });
      localStorage.removeItem('user');
      localStorage.removeItem('bearer-token');
      localStorage.removeItem('refresh-bearer-token');
      localStorage.removeItem('expiry');
      if (logout.status === 200 || logout.status === 201) {
        return;
      } else {
        return rejectWithValue('Error logging out');
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const refreshAccessToken = createAsyncThunk<
  User,
  void,
  {rejectValue: string}
>('auth/refreshAccessToken', async (_, {rejectWithValue}) => {
  try {
    const user = JSON.parse(localStorage.getItem('user') || '');

    return user;
  } catch (error: any) {
    return rejectWithValue('User Not Found');
  }
});

export const useReduxAuth = (state: RootState) => state.authSlice;
export default authSlice.reducer;
