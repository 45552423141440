import {cilMenu} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {CContainer, CHeader, CHeaderNav, CHeaderToggler} from '@coreui/react';
import React from 'react';

import {setSidebarShow, useSidebarSelector} from '../slices/togglerSlicer';
import {useAppDispatch} from '../store';

import {useSelector} from 'react-redux';
import AppHeaderDropDown from './AppHeaderDropDown';

function AppHeader() {
  const dispatch = useAppDispatch();
  const sidebarState = useSelector(useSidebarSelector);

  function handleSetToggle() {
    dispatch(setSidebarShow(!sidebarState.sidebarShow));
  }

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler onClick={handleSetToggle}>
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderNav className="ms-3">
          <AppHeaderDropDown />
        </CHeaderNav>
      </CContainer>
    </CHeader>
  );
}

export default AppHeader;
