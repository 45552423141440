import {CCard, CCardHeader, CBadge, CCardBody} from '@coreui/react';
import React from 'react';
import {FaStopCircle} from 'react-icons/fa';

interface SessionCardProps {
  topic: string;
  message: string;
  timestamp: number;
}
export function SessionCard({topic, message, timestamp}: SessionCardProps) {
  return (
    <div>
      <CCard>
        <CCardHeader>
          <FaStopCircle className="me-2" />

          <strong>{topic}</strong>
          <CBadge className="float-end" color="info">
            {new Date(timestamp).toLocaleTimeString('it-IT', {
              timeZone: 'Europe/Rome',
            })}
          </CBadge>
        </CCardHeader>
        <CCardBody>{message}</CCardBody>
      </CCard>
    </div>
  );
}
